import '../assets/css/Footer.css';

const Footer = () => {
    return <>
    
    <div className="footer">
        <p>© 2024 Copyright by ShowMe</p>
        <span>대표이사 : 김영복 | 사업자등록번호 : 230-81-13575</span>
        {/* <span>주소 : 부산광역시 남구 수영로 274-17 2층</span> */}
        <span>대표번호 : 1600-1796 | Fax : 051-628-1792</span>
        <span>E.mail : kyb@will-pay.co.kr</span>
    </div>
    
    </>
}

export default Footer;