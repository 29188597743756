import Layout from '../layouts/Layout.js';

function CustomerCenter() {
  return (
    <>
      <Layout>
        <div className="">
          
        </div>
        <div className=""></div>
      </Layout>
    </>
  );
}



export default CustomerCenter;