import {React, useState} from 'react';
import "../assets/css/Header.css";
import main_logo_dark from "../assets/img/main_logo_dark.png";
import HamburgurMenu from './HamburgurMenu';

const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    window.addEventListener('scroll', ()=>{
        if(window.scrollY >= window.innerHeight-20) setIsScrolled(true);
        else setIsScrolled(false);
    });

    return (
        <div className={ isScrolled? "header scrolled_header" : "header"}>
            <div className="header_container">
                <a href="/">
                    <div className="header_logo_continer">
                        <img src={main_logo_dark} className="header_logo"/>
                        <h2>Show Me</h2>
                    </div>
                </a>
                <div className={"header_menu"}>
                    <a href="/about">About</a>
                    <a href="/service">Service</a>
                    <a href="/portfolio">Portfolio</a>
                    <a href="/customerCenter">CustomerCenter</a>
                    <HamburgurMenu/>
                </div>
            </div>
        </div>
    )
}

export default Header;