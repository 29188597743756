import "../assets/css/HamburgurMenu.css";

const HamburgurMenu = () => {
    return <>
    <div className="hamburgur_menu">
        <input type="checkbox" id="icon" />
        <label htmlFor="icon">
            <span></span>
            <span></span>
            <span></span>
        </label>
    </div>

    </>
}

export default HamburgurMenu;