import Layout from '../layouts/Layout.js';
import main_img from '../assets/img/main_img.jpg';
import '../assets/css/Homepage.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

import $ from 'jquery';
import { useEffect } from 'react';

function HomePage() {
  
  const products = [
    {
      name:"생각하는 도현상",
      content:"자세하게 어떤 내용이 들어가야 하는 지 모르겠네요. 그래서 이렇게 한땀한땀 써봅니다. 저는 생각하는 도현상입니다.",
      img:"/assets/img/main_img.jpg"
    },
    {
      name:"생각하는 현도상",
      content:"자세하게 어떤 내용이 들어가야 하는 지 모르겠네요. 그래서 이렇게 한땀한땀 써봅니다. 저는 생각하는 현도상입니다.",
      img:"/assets/img/main_img.jpg"
    },
    {
      name:"생각하는 쿠로상",
      content:"자세하게 어떤 내용이 들어가야 하는 지 모르겠네요. 그래서 이렇게 한땀한땀 써봅니다. 저는 생각하는 쿠로상입니다.",
      img:"/assets/img/main_img.jpg"
    }
  ];
  

  useEffect(()=>{
    AOS.init();
  });

  
  return (
    <>
      <Layout>
        <div className="main frame_1">
          <img src={main_img} className="main_img"/>
          <div className="img_wrap"></div>
          <div className="main_container">
            <div className="main_sub_content">
              <span data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">또 다른 나를</span>
              <span data-aos="fade-up" data-aos-delay="1000" data-aos-duration="1000">보여줄게</span>
            </div>
            <div className="main_content" data-aos="fade-up" data-aos-delay="1500" data-aos-duration="1000">
              <span className="">Show Me</span>
            </div>
          </div>
        </div>
        <div className="viewer frame_2">
          <div className="viewer_content">
            {
              products.map((value, index) => {
                return <div key={index}>
                  {value.content} index : {index+1}

                </div>
              })
            }
          </div>
        </div>
      </Layout>
    </>
  );
}

export default HomePage;