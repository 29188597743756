import Layout from '../layouts/Layout.js';

function Portfolio() {
  return (
    <>
      <Layout>
        <div className="">
          
        </div>
        <div className=""></div>
      </Layout>
    </>
  );
}



export default Portfolio;