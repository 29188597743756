import Layout from '../layouts/Layout.js';
import '../assets/css/Homepage.css';

function About() {
  return (
    <>
      <Layout>
        <div className="main">
          <div className="main_container">
            <div className="main_sub_content">
              <span className="">또 다른 나를</span>
              <span className="">보여줄게</span>
            </div>
            <div className="main_content">
              <span className="">Show Me</span>
            </div>
          </div>
        </div>
        <div className=""></div>
      </Layout>
    </>
  );
}



export default About;