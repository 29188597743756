import React, { useEffect } from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer';

import '../assets/css/layout.css';

const Layout = (props) =>{

    return (
        <>
        <div className='layout'>
            <Header />
            {props.children}
            <Footer />
        </div>
        </>
    )
}

export default Layout;